import React from 'react';
import classnames from 'classnames';

import Text from "../../../../components/Text";

import styles from './index.module.css';

class Answer extends React.Component {
  render() {
    return (
      <div className={classnames(styles['answer'], this.props.className, this.props.isActive && 'is-active')}
           onClick={this.props.onClick}
      >
        <div className={styles['answer--dot']} />
        <Text type="p" className={styles['answer--text']}>{this.props.children}</Text>
      </div>
    )
  };
}

export default Answer;
