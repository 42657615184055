import React from 'react';
import styles from './index.module.css';


const Social = ({icon, link}) => (<a href={link} rel="noopener noreferrer" target="_blank" className={styles.social}><img src={icon} className={styles.socialIcon} alt=""/></a>);

const getShareLinks = ({postUrl, title}) => {
  const links = {
    ok: `https://connect.ok.ru/offer?url=${postUrl}&title=${encodeURIComponent(title)}`,
    vk: `https://vk.com/share.php?url=${postUrl}&title=${title}`,
    fb: `https://www.facebook.com/v2.8/dialog/share?display=popup&href=${postUrl}&quote=${title}`,
    twi: `https://twitter.com/intent/tweet?text=${title}&url=${postUrl}`,
  };

  return links;
};

const shareLinks = getShareLinks({postUrl: window.location.href, title: 'Обман на подъёмнике. Насколько хорошо вы знаете свой автомобиль?'});


class Button extends React.Component {
  render() {
    return (
      <div>
        <Social icon={require('../../../../assets/icons/facebook@2x.png')} link={shareLinks.fb}/>
        <Social icon={require('../../../../assets/icons/vk@2x.png')} link={shareLinks.vk}/>
        <Social icon={require('../../../../assets/icons/tw@2x.png')} link={shareLinks.twi}/>
        <Social icon={require('../../../../assets/icons/ok@2x.png')} link={shareLinks.ok}/>
      </div>
    )
  };
}

export default Button;
