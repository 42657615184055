const questions = [
  {
    count: 1, // ВОПРОС 1
    question: {
      title: '',
      description: 'После диагностики двигателя автомеханик говорит, что у вас провернуло вкладыши, но у его знакомого есть “точно такие же” и заменить можно за два часа. Стоит ли соглашаться на такую процедуру?',
      image: require('../assets/question/1.jpg')
    },
    buttons: [
      {
        title: 'Почему бы и нет. Чем быстрее ремонт, тем быстрее заберу машину',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'Установка некачественных вкладышей грозит заклиниванием двигателя и его полным отказом. Покупать такие детали нужно только по номеру двигателя после серии диагностических процедур.'
        }
      },
      {
        title: 'Посоветуюсь с друзьями. Наверняка кто-нибудь из них сталкивался с такой проблемой',
        answer: {
          value: 1,
          title: 'Почти попал! 1 балл',
          description: 'Установка некачественных вкладышей грозит заклиниванием двигателя и его полным отказом. Покупать такие детали нужно только по номеру двигателя после серии диагностических процедур.'
        }
      },
      {
        title: 'Не буду соглашаться на месте. Детали двигателя нужно подбирать по каталогу, автомобильные магазины и официальный дилер лучше справятся с задачей',
        answer: {
          value: 2,
          title: 'Правильно!',
          description: 'Установка некачественных вкладышей грозит заклиниванием двигателя и его полным отказом. Покупать такие детали нужно только по номеру двигателя после серии диагностических процедур.'
        }
      }
    ]
  },
  {
    count: 2, // ВОПРОС 2
    question: {
      title: '',
      description: 'После жалоб на повышенный расход топлива мастер предлагает срочно “перепрошить” автомобиль и обещает, что всё будет работать как часы.',
      image: require('../assets/question/2.jpg'),
    },
    buttons: [
      {
        title: 'Чип-тюнинг — популярная процедура, после которой наблюдаются заметные улучшения. Можно соглашаться на месте',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'Сам по себе чип-тюнинг безопасен, если проводится у сертифицированного дилера. “Серые” прошивки могут быть опасны, а владелец машины рискует лишиться гарантии завода.'
        }
      },
      {
        title: 'Почитаю отзывы на форумах, возьму время на принятие решения',
        answer: {
          value: 1,
          title: 'Неверно!',
          description: 'Сам по себе чип-тюнинг безопасен, если проводится у сертифицированного дилера. “Серые” прошивки могут быть опасны, а владелец машины рискует лишиться гарантии завода.'
        }
      },
      {
        title: 'Сначала проконсультируюсь с другими специалистами. Наверняка у процедуры есть подводные камни',
        answer: {
          value: 2,
          title: 'Верно!',
          description: 'Сам по себе чип-тюнинг безопасен, если проводится у сертифицированного дилера. “Серые” прошивки могут быть опасны, а владелец машины рискует лишиться гарантии завода.'
        }
      }
    ]
  },
  {
    count: 3, // ВОПРОС 3
    question: {
      title: '',
      description: 'После того как в сервисе установили причину “дёргания” автомобиля при движении, мастер предлагает почистить дроссельную заслонку. За процедуру попросил 25 тыс. рублей.',
      image: require('../assets/question/3.jpg'),
    },
    buttons: [
      {
        title: 'Если это поможет, то можно заплатить любые деньги',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'Чистка дроссельной заслонки не самая сложная процедура. Специальная химия и набор из пары отвёрток помогут провести процедуру самостоятельно. Можно обойтись без заезда в сервис, но проблем может оказаться больше, чем ожидалось.'
        }
      },
      {
        title: 'Позвоню другим специалистам и поинтересуюсь, сколько на самом деле стоит такая процедура и что она даёт',
        answer: {
          value: 2,
          title: 'Неверно!',
          description: 'Чистка дроссельной заслонки не самая сложная процедура. Специальная химия и набор из пары отвёрток помогут провести процедуру самостоятельно. Можно обойтись без заезда в сервис, но проблем может оказаться больше, чем ожидалось.'
        }
      },
      {
        title: 'Попытаюсь вспомнить, что такое дроссельная заслонка, и если что, почищу сам',
        answer: {
          value: 1,
          title: 'Верно!',
          description: 'Чистка дроссельной заслонки не самая сложная процедура. Специальная химия и набор из пары отвёрток помогут провести процедуру самостоятельно. Можно обойтись без заезда в сервис, но проблем может оказаться больше, чем ожидалось.'
        }
      }
    ]
  },
  {
    count: 4, // ВОПРОС 4
    question: {
      title: '',
      description: 'Жалобы на стук и гул в переднем правом колесе. В сервисе говорят, что дело в ступичном подшипнике, но менять нужно всю деталь сразу, желательно и с левой, и с правой стороны.',
      image: require('../assets/question/4.jpg'),
    },
    buttons: [
      {
        title: 'Подвеска на иномарках редко бывает дешёвой. Скорее всего, мастер не обманывает',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'Ступичный подшипник часто меняется без замены всей ступицы. Нечистые на руку мастера пользуются незнанием автолюбителей и зарабатывают на этом приличные деньги.'
        }
      },
      {
        title: 'Уточнить у механиков официального дилера, как правильно менять деталь и сколько это стоит',
        answer: {
          value: 2,
          title: 'Верно!',
          description: 'Ступичный подшипник часто меняется без замены всей ступицы. Нечистые на руку мастера пользуются незнанием автолюбителей и зарабатывают на этом приличные деньги.'
        }
      },
      {
        title: 'Откажусь и поеду на диагностику в другой сервис',
        answer: {
          value: 1,
          title: 'Неверно!',
          description: 'Ступичный подшипник часто меняется без замены всей ступицы. Нечистые на руку мастера пользуются незнанием автолюбителей и зарабатывают на этом приличные деньги.'
        }
      }
    ]
  },
  {
    count: 5, // ВОПРОС 5
    question: {
      title: '',
      description: 'Из-за вибраций двигателя мастер предлагает поменять опоры двигателя. Оригинальные стоят дорого, китайские дешевле.',
      image: require('../assets/question/5.jpg'),
    },
    buttons: [
      {
        title: 'Поставлю китайские. Всё равно ещё пару лет, и машину придётся продавать',
        answer: {
          value: 1,
          title: 'Неверно!',
          description: 'В крупных магазинах автозапчастей точно знают, какие опоры двигателя и от какого производителя нужно ставить на автомобиль.'
        }
      },
      {
        title: 'Посоветуюсь с менеджером из приличного автомобильного магазина, вдруг оригинальные стоят не так дорого, как говорит мастер',
        answer: {
          value: 2,
          title: 'Верно!',
          description: 'В крупных магазинах автозапчастей точно знают, какие опоры двигателя и от какого производителя нужно ставить на автомобиль.'
        }
      },
      {
        title: 'Не буду менять. Деньги потрачу на бензин',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'В крупных магазинах автозапчастей точно знают, какие опоры двигателя и от какого производителя нужно ставить на автомобиль.'
        }
      }
    ]
  },
  {
    count: 6, // ВОПРОС 6
    question: {
      title: '',
      description: 'Перед плановым обслуживанием автомеханик предлагает заливать в двигатель другое масло. Естественно, более дорогое и хорошее, которое есть в наличии.',
      image: require('../assets/question/6.jpg'),
    },
    buttons: [
      {
        title: 'Соглашаться, пожалуй, не стоит. Мало ли откуда его привезли и из чего сделали',
        answer: {
          value: 1,
          title: 'Неверно!',
          description: 'Каждый производитель рекомендует для своих машин определённое масло. Если масло от другого бренда соответствует параметрам "заводского" масла по вязкости, то можно смело использовать не то, что рекомендовал завод.'
        }
      },
      {
        title: 'Посмотрю в Интернете, какое масло рекомендовано для моей машины, и уже потом дам ответ',
        answer: {
          value: 2,
          title: 'Верно!',
          description: 'Каждый производитель рекомендует для своих машин определённое масло. Если масло от другого бренда соответствует параметрам "заводского" масла по вязкости, то можно смело использовать не то, что рекомендовал завод.'
        }
      },
      {
        title: 'Соглашусь, дороже — значит лучше. У масла не самый сложный состав, и разобраться в нём довольно просто',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'Каждый производитель рекомендует для своих машин определённое масло. Если масло от другого бренда соответствует параметрам "заводского" масла по вязкости, то можно смело использовать не то, что рекомендовал завод.'
        }
      }
    ]
  },
  {
    count: 7, // ВОПРОС 7
    question: {
      title: '',
      description: 'После небольшого ДТП виновник предлагает "решить вопрос" на месте, но автомобиль застрахован по каско.',
      image: require('../assets/question/7.jpg'),
    },
    buttons: [
      {
        title: 'Нужно посмотреть повреждения. Если царапина, то почему бы и нет',
        answer: {
          value: 1,
          title: 'Неверно!',
          description: 'Даже после столкновения на скорости в 20 километров в час силовые элементы кузова могут быть повреждены. Сам бампер может и не треснуть, но некоторые элементы под ним могут прийти в негодность, и их ремонт обойдётся владельцу в кругленькую сумму.'
        }
      },
      {
        title: 'Сначала позвоню в страховую. Мало ли какие повреждения будут внутри',
        answer: {
          value: 2,
          title: 'Верно!',
          description: 'Даже после столкновения на скорости в 20 километров в час силовые элементы кузова могут быть повреждены. Сам бампер может и не треснуть, но некоторые элементы под ним могут прийти в негодность, и их ремонт обойдётся владельцу в кругленькую сумму.'
        }
      },
      {
        title: 'Не буду обращать внимания. Царапины на автомобиле и так появятся',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'Даже после столкновения на скорости в 20 километров в час силовые элементы кузова могут быть повреждены. Сам бампер может и не треснуть, но некоторые элементы под ним могут прийти в негодность, и их ремонт обойдётся владельцу в кругленькую сумму.'
        }
      }
    ]
  },
  {
    count: 8, // ВОПРОС 8
    question: {
      title: '',
      description: 'Автомобиль внезапно начал греться. В сервисе без должной диагностики сразу предложили поменять водяной насос (помпу).',
      image: require('../assets/question/8.jpg'),
    },
    buttons: [
      {
        title: 'Соглашусь. Причина может быть и в ней',
        answer: {
          value: 0,
          title: 'Неверно!',
          description: 'Система охлаждения — сложный механизм. Без диагностики и уточнения данных о марке и модели автомобиля менять элементы по одному нет никакого смысла.'
        }
      },
      {
        title: 'Спрошу у друзей и попрошу провести диагностику',
        answer: {
          value: 1,
          title: 'Неверно!',
          description: 'Система охлаждения — сложный механизм. Без диагностики и уточнения данных о марке и модели автомобиля менять элементы по одному нет никакого смысла.'
        }
      }, {
        title: 'Позвоню в другой сервис, а затем официальному дилеру и уточню, какие детали могут понадобиться для ремонта, узнаю, в чём может быть причина, опираясь на марку и модель автомобиля',
        answer: {
          value: 2,
          title: 'Верно!',
          description: 'Система охлаждения — сложный механизм. Без диагностики и уточнения данных о марке и модели автомобиля менять элементы по одному нет никакого смысла.'
        }
      }
    ]
  },
];

const results = [ // РЕЗУЛЬТАТЫ ТЕСТА
  {
    limits: {
      min: 0,
      max: 5
    },
    info: {
      title: '',
      image: require('../assets/results/img_final.jpg'),
      description: 'Вы — начинающий автолюбитель. Идеальный клиент для любого сервиса и лёгкая добыча для недобросовестного мастера. Не понимаете разницы между антифризом и моторным маслом, даже если они стоят на одной полке в разных бутылках. С большой вероятностью без консультации специалиста вас обманут. Для начинающих водителей особенно рекомендуем приобрести полис каско, например, в <a href="https://www.ingos.ru" target="_blank">"Ингосстрахе"</a>. Рассчитайте свой полис на сайте компании или в мобильном приложении IngoMobile.'
    },
    share: {
      text: 'Обман на подъёмнике. Насколько хорошо вы знаете свой автомобиль?'
    }
  },
  {
    limits: {
      min: 6,
      max: 10
    },
    info: {
      title: '',
      image: require('../assets/results/result-2.jpg'),
      description: 'Вы что-то слышали о качественном обслуживании и знаете, как выглядят тормозные колодки и масляный фильтр. Однако понятия не имеете, где и как лучше всего произвести их замену. Чтобы не переплатить три цены за ремонт и обслуживание своей машины, стоит внимательнее присмотреться к полису каско, например, от <a href="https://www.ingos.ru" target="_blank">"Ингосстраха"</a>. Рассчитайте свой полис на сайте компании или в мобильном приложении IngoMobile.'
    },
    share: {
      title: '',
      text: 'Обман на подъёмнике. Насколько хорошо вы знаете свой автомобиль?'
    }
  },
  {
    limits: {
      min: 11,
      max: 16
    },
    info: {
      title: '',
      image: require('../assets/results/result-1.jpg'),
      description: 'Вы — гуру обслуживания автомобиля. Знание деталей автомобиля — близкое к совершенному. Ремень генератора, подшипник компрессора кондиционера и втулку стабилизатора можете найти самостоятельно. Но даже такие профессионалы, как вы, тоже могут ошибаться, поэтому всё же советуем приобрести полис каско в <a href="https://www.ingos.ru" target="_blank">"Ингосстрахе"</a>. Рассчитайте свой полис на сайте компании или в мобильном приложении IngoMobile. '
    },
    share: {
      text: 'Обман на подъёмнике. Насколько хорошо вы знаете свой автомобиль?'
    }
  },
]

function getResult(value) {
  for (let i = 0; i < results.length; ++i) {
    if (value >= results[i].limits.min && value <= results[i].limits.max) {
      return results[i]
    }
  }
  return results[results.length - 1]
}

export default {
  questions,
  results,
  getResult
};
