import React from 'react';
import classnames from 'classnames';
import styles from './index.module.css';

const getProgressLineStyle = (current, count) => {
  return {
    transform: 'scaleX(' + current/count + ')',
  }
};

class Progress extends React.Component {
  render() {
    return (
      <div className={classnames(styles['progress'], this.props.isActive && 'is-active')}>
        <div className={styles['progress--line']}>
          <div className={styles['progress--line--fill']}
               style={getProgressLineStyle(this.props.current, this.props.count)}
          />
        </div>
        <div className={styles['progress--count']}>{this.props.current}/{this.props.count}</div>
      </div>
    )
  };
}

export default Progress;
