import React from 'react';

import styles from './index.module.css';

class Logo extends React.Component {
  render() {
    return <a href="/"><img src={require('../../assets/logo_ingo.png')} className={styles.logo} alt="Логотип Ингосстрах"/></a>
  };
}

export default Logo;
