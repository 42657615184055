import React from 'react';
import styles from './index.module.css';

import Button from '../../components/Button';
import Logo from '../../components/Logo';
import Text from '../../components/Text';

import AnimationMan from "./components/AnimationMan";

class Start extends React.Component {
  render() {
    return (
      <div className={styles['start']}>
        <main className={styles['start--main']}>
          <header className={styles['start--header']}>
            <Logo />
          </header>
          <section className={styles['start--content']}>
            <Text type="h1" className={styles['start--title']}>
              Обман на подъёмнике. Насколько хорошо вы знаете свой автомобиль?
            </Text>
            <Text type="p" className={styles['start--text']}>
              Иногда автовладельцы думают, что могут самостоятельно справиться с любым
              ремонтом. Реальность полна разочарований — большую часть из того, что мастера
              рассказывают о ремонте в сервисах, сложно понять и ещё сложнее правильно
              оценить. Life и страховая компания &quot;Ингосстрах&quot; подготовили специальный тест на
              знание процедур ремонта автомобилей. Какой из вас автомеханик?
            </Text>
            <div className={styles['start--button']}>
              <Button onClick={this.props.onPlay}>Начать</Button>
            </div>
          </section>
          <footer className={styles['start--footer']}></footer>
        </main>
        <div className={styles['start--aside']}>
          <AnimationMan />
        </div>
      </div>
    )
  };
}

export default Start;
