import React from 'react';
import classnames from 'classnames';

import TimeActionButton from '../../components/TimeActionButton';
import Text from '../../components/Text';
import Logo from "../../components/Logo";

import Answer from "./components/Answer";
import Progress from "./components/Progress";

import styles from './index.module.css';

class Game extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: this.props.data.count,
      image: this.props.data.question.image,
      title: this.props.data.question.description,
      buttons: this.props.data.buttons,

      complete: false,
      value: 0,
      currentActiveIndex: -1,
    };

    this.scrollToTop();

    this.setInitialState = this.setInitialState.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  scrollToTop() {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  scrollToBottom() {
    window.scroll({top: 1000, left: 0, behavior: 'smooth'});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.count !== this.props.data.count) {
      this.setInitialState();
    }
  }

  setInitialState() {
    this.setState({
      count: this.props.data.count,
      image: this.props.data.question.image,
      title: this.props.data.question.description,
      buttons: this.props.data.buttons,

      complete: false,
      value: 0,
      currentActiveIndex: -1,
    })
  }

  onComplete(idx) {
    const data = this.state.buttons[idx].answer;
    const result = ({
      0: ['Неверно!', '0 баллов'],
      1: ['Почти попал!', '1 балл'],
      2: ['Верно!', '2 балла'],
    })[data.value];

    this.setState({
      complete: true,
      value: data.value,
      resultText: result[0],
      score: result[1],
      description: data.description,
      currentActiveIndex: idx,
    });

    this.props.onComplete(data.value);

    this.scrollToBottom();
  }

  onNext() {
    this.props.onNext();

    this.scrollToTop();
  }

  render() {
    return (
      <div className={styles['game']}>
        <main className={styles['game--main']}>
          <header className={styles['game--header']}>
            <Logo/>
          </header>
          <section className={styles['game--content']}>
            <Text type="h2" className={styles['game--title']}>
              {this.state.title}
            </Text>
            <div>
              {this.state.buttons.map((item, idx) => (
                <Answer
                  isActive={idx === this.state.currentActiveIndex}
                  className={styles['game--answer']}
                  key={item.title}
                  onClick={() => {
                    if (this.state.complete) return;

                    this.onComplete(idx)
                  }}
                >
                  {item.title}
                </Answer>
              ))}
            </div>
          </section>
          <footer className={styles['game--footer']}>
            <Progress count={8} current={this.state.count}/>
          </footer>
        </main>
        <div className={styles['game--aside']}>
          <img className={styles['game--image']} src={this.state.image} alt=""/>
          <div className={classnames(styles['game--result'], this.state.complete && 'is-shown')}>
            <div className={styles['game--result--content']}>
              <div className={styles["game--result--score"]}>{this.state.score}</div>
              <Text type="p" className={styles["game--result--text"]}>{this.state.description}</Text>
              {this.state.complete && (
                <TimeActionButton key={this.state.count}
                                  time={8}
                                  action={this.onNext}
                                  className={styles['game--result--button']}
                >
                  Следующий вопрос
                </TimeActionButton>
              )}

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Game;
