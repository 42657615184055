import React from 'react';

import classnames from 'classnames';
import styles from './index.module.css';

class Text extends React.PureComponent {
  render() {
    switch (this.props.type) {
      case 'h1':  return <p className={classnames([styles.h1, this.props.className])}>{this.props.children}</p>;
      case 'h2':  return <p className={classnames([styles.h2, this.props.className])}>{this.props.children}</p>;
      case 'h3':  return <p className={classnames([styles.h3, this.props.className])}>{this.props.children}</p>;
      case 'p':
      default:  return <p className={classnames([styles.p, this.props.className])}>{this.props.children}</p>;
    }
  };
}

export default Text;
