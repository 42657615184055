import React from 'react';
import classnames from 'classnames';
import styles from './index.module.css';

const getButtonBackgroundStyle = ({time, isActive}) => {
  return {
    transitionDuration: `${time}s`,
    transform: 'scaleX(' + (isActive ? 1 : 0) + ')',
  }
};

class TimeActionButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };

    this.startActionTimer = this.startActionTimer.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  componentDidMount() {
    setTimeout(this.startActionTimer, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.actionTimer);
  }

  handleAction() {
    clearTimeout(this.actionTimer);

    this.props.action();
  }

  startActionTimer() {
    this.setState({
      isActive: true
    }, () => {
      this.actionTimer = setTimeout(this.handleAction, this.props.time * 1000);
    });
  }

  render() {
    const style = getButtonBackgroundStyle({time: this.props.time, isActive: this.state.isActive});

    return (
      <div className={classnames(styles['button'], this.props.className)}
              onClick={this.handleAction}>
        <div className={styles["button--background"]} style={style} />
        <div className={styles["button--text"]}>{this.props.children}</div>
      </div>
    )
  };
}

export default TimeActionButton;
