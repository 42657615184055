import React from 'react';
// import classnames from 'classnames';
import styles from './index.module.css';

import declOfNum from '../../utils/declOfNum';

import Button from '../../components/Button';
import Logo from "../../components/Logo";
import Text from "../../components/Text";

import Share from './components/Share';



class End extends React.Component {
  render() {
    return (
      <div className={styles['end']}>
        <main className={styles['end--main']}>
          <header className={styles['end--header']}>
            <Logo/>
          </header>
          <div className={styles['end--content']}>
            <span className={styles['end--signature']}>
              Результат:
            </span>

            <Text type="h1" className={styles['end--title']}>
              {this.props.result} {declOfNum(this.props.result, ['балл', 'балла', 'баллов'])}
            </Text>

            <Text type="p" className={styles['end--description']}>
              <span dangerouslySetInnerHTML={{__html: this.props.data.info.description}}/>
            </Text>

            <div className={styles['end--buttons']}>
              <a className={styles['end--buttons_button']}
                 href="https://www.ingos.ru/auto/kasko/calc/"
                 target="_blank"
                 rel="noopener noreferrer"
              >
                <Button>
                  Калькулятор Каско
                </Button>
              </a>
              <div className={styles['end--buttons_button-again']}
                      onClick={this.props.onRestart}
              >
                Пройти еще раз
              </div>
            </div>
          </div>
          <footer className={styles["end--footer"]}>
            <Share/>
          </footer>
        </main>
        <div className={styles["end--aside"]}>
          <img className={styles['end--image']} src={this.props.data.info.image} alt={this.props.data.info.title}/>
        </div>
      </div>
    )
  };
}

export default End;
