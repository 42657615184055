import React from 'react';
// kick off the polyfill!
import 'normalize.css';
import './App.css';

// import sharer from './utils/sharer';
// import shuffle from './utils/shuffle';

import Start from './pages/Start';
import Game from './pages/Game';
import End from './pages/End';


import data from './utils/data';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();


// TODO: запустить логгер

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuestion: 0,
      isStart: true,
      isGame: false,
      isEnd: false,
    }

    this.result = 0;

    this.onPlay = this.onPlay.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onRestart = this.onRestart.bind(this);

    // try {
    //   sharer.init('https://vk.com/app7118013_-152992737', 'social-2019-znanie');
    // } catch(e) {
    //   console.error(e)
    // }
  }

  onPlay() {
    // shuffle(data.questions);
    this.setState({
      isStart: false,
      isGame: true
    })
  }

  onComplete(value) {
    this.result = this.result + value;
  }

  onNext() {
    this.setState((state) => {
      let currentQuestion = state.currentQuestion + 1;

      if (currentQuestion === data.questions.length) {
        return {
          isGame: false,
          isEnd: true
        }
      } else {
        return {
          currentQuestion
        }
      }
    })
  }

  onRestart() {
    this.setState({
      isGame: true,
      isEnd: false,
      currentQuestion: 0
    })

    this.result = 0;

    // shuffle(data.questions);
  }


  render() {
    // console.log(this.result)
    return <React.Fragment>
      {
        this.state.isStart && <Start onPlay={this.onPlay}/>
      }
      {
        this.state.isGame && <Game onComplete={this.onComplete} onNext={this.onNext} data={data.questions[this.state.currentQuestion]} index={this.state.currentQuestion + 1}/>
      }
      {
        this.state.isEnd && <End data={data.getResult(this.result)} result={this.result} onRestart={this.onRestart} />
      }
      
    </React.Fragment>
  };
}

export default App;
