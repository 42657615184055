import React from 'react';
import cx from 'classnames';

import styles from './index.module.css';

const mouthImages = [
  require('../../../../assets/animation/mouth_1.png'),
  require('../../../../assets/animation/mouth_2.png'),
  // require('../../../../assets/animation/mouth_3.png'),
  require('../../../../assets/animation/mouth_4.png'),
  require('../../../../assets/animation/mouth_5.png'),
];

const manImage = require('../../../../assets/animation/man.png');
const bubbleImage = require('../../../../assets/animation/bubble.png');
const backImage = require('../../../../assets/animation/back.jpg');

const animFlow = (scenes = []) => {
  scenes.forEach(scene => {
    setTimeout(scene.action, scene.delay);
  })
};

const iterateOver = (action, count, delay) => {
  let idx = 0;

  const step = () => {
    if (idx >= count) return;

    action();
    idx++;

    setTimeout(step, delay);
  };

  step();
};

const getRandomSymbol = () => {
  const symbols = '#!%&#$@*'.split('');

  return symbols[Math.floor(Math.random() * symbols.length)];
};

class AnimationMan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouthImageIdx: 0,
      step: '0',
      word: ''
    };

    this.spellWord = this.spellWord.bind(this);
  }

  componentDidMount() {
    animFlow([
      {
        action: () => this.setState({step: '1'}),
        delay: 200,
      },
      {
        action: this.spellWord,
        delay: 2000,
      },
      {
        action: this.spellWord,
        delay: 4000,
      },
      {
        action: this.spellWord,
        delay: 6500,
      }
    ])
  }

  spellWord() {
    const spellOneSymbol = () => {
      const nextMouthIdx = this.state.mouthImageIdx === mouthImages.length - 1 ? 0 : this.state.mouthImageIdx + 1;

      this.setState({
        mouthImageIdx: nextMouthIdx,
        word: this.state.word + getRandomSymbol()
      })
    };

    this.setState({
      word: ''
    }, () => {
      iterateOver(spellOneSymbol, 8, 200);
    });
  }

  render() {
    return (
      <div className={cx(styles.wrapper, 'step-'+this.state.step)}>
        <img src={backImage} className={styles.backImage} alt=""/>
        <div className={styles.manWrapperScene}>
          <div className={styles.manWrapper}>
            <img className={styles.manImage} src={manImage} alt=""/>
            <img className={styles.mouthImage} src={mouthImages[this.state.mouthImageIdx]} alt=""/>
            <div className={styles.bubbleWrapper}>
              <img className={styles.bubbleImage} src={bubbleImage} alt=""/>
              <div className={styles.word}>{this.state.word}</div>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

export default AnimationMan;
